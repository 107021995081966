<template>
  <div class="add-blog">
    <div class="add-blog-head">
      <input class="title-input" placeholder="输入文章标题..." v-model="blog.blogTitle">
      <button class="add-blog-btn" @click="showSummary=true">发布</button>
    </div>
    <!-- markdown编辑器 -->
    <mavon-editor class="add-blog-con" v-model="blog.blogContent" :toolbars="toolbars" style=""/>
    <!-- 最终发布前 填写摘要 -->
    <div class="add-blog-summary" v-show="showSummary">
      <div class="add-blog-summary-title">发布文章</div>
      <div class="add-blog-summary-items">
        <div class="add-blog-summary-con">
          <span>摘要：</span>
          <label>
            <textarea class="add-blog-summary-text" placeholder="输入摘要内容..." v-model="blog.blogSummary"></textarea>
          </label>
        </div>
      </div>
      <div class="add-blog-summary-footer">
        <button class="add-blog-btn sub" @click="postSub">提交</button>
        <button class="add-blog-btn cal" @click="postCal">取消</button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isEdit: false,
        showSummary: false,
        blog: {
          blogContent: '',//输入的数据
          blogTitle: '',
          blogSummary: ''
        },
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          mark: true, // 标记
          superscript: true, // 上角标
          quote: true, // 引用
          ol: true, // 有序列表
          link: true, // 链接
          imagelink: true, // 图片链接
          help: true, // 帮助
          code: true, // code
          subfield: true, // 是否需要分栏
          fullscreen: false, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          /* 1.3.5 */
          undo: true, // 上一步
          trash: true, // 清空
          save: true, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: true // 导航目录
        }
      }
    },
    mounted() {
      this.getBlogDetail();
    },
    methods: {
      getBlogDetail() {
        let blogId = this.$route.query.blogId
        this.$http.get(
            'blog/getBlogDetail',
            {params: {blogId: blogId}}
        ).then(res => {
          if (res.data.success) {
            this.blog = {...res.data.data};
            this.isEdit = true;
          }
        })
      },
      postSub() {
        let submitUrl = "blog/addBlog";
        if (this.isEdit){
          submitUrl = "blog/editBlog";
        }
        this.$http.post(submitUrl, this.blog).then(res => {
          if (res.data.success) {
            this.showSummary = false;
            this.$router.push('/')
          }
        })
      },
      postCal() {
        this.showSummary = false;
      },
    }
  }
</script>
<style>
  .add-blog-btn.cal {
    margin-left: 1vw;
  }

  .add-blog-summary-items {
    height: 58%;
  }

  .add-blog-summary-footer {
    display: flex;
    justify-content: center;
  }

  .add-blog-summary-text {
    border: 1px solid #e6e8eb;
    border-radius: 2px;
    position: relative;
    width: 85%;
    display: inline-block;
    color: #282f38;
    line-height: 1.5;
    background-color: #fff;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    height: auto;
    padding: 6px 10px;
    vertical-align: top;
    resize: vertical;
  }

  .add-blog-summary-con {
    margin: 2vh 5vw 1vh 3vw;
  }

  .add-blog-summary-title {
    border-bottom: 1px solid #e5e6eb;
    padding: 2vh 5vw 1vh 3vw;
  }

  .add-blog-summary {
    width: 50vw;
    height: 30vh;
    position: absolute;
    right: 0;
    top: 15vh;
    z-index: 1501;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 0 1px 2px #f1f1f1;
    background-color: white;


  }

  .add-blog-btn {
    height: 32px;
    padding: 2px 16px;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #1d7dfa;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #1d7dfa;
  }

  .add-blog-head {
    display: flex;
    align-items: center;
    padding: 0 27px;
    height: 10vh;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    z-index: 100;
  }

  .add-blog-con {
    height: 90vh;
  }

  .title-input {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    color: #1d2129;
    border: none;
    outline: none;
    flex: 1 1 auto;
    height: 100%;
  }

  .add-blog {
    height: 100vh;
  }
</style>
